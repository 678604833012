'use client';

import React from 'react';
import { onValue, ref } from 'firebase/database';
import { authFirebase, database } from '@/lib/firebase';
import { onAuthStateChanged, signInAnonymously, User } from 'firebase/auth';
// import useFcmToken from '@/hooks/use-fcm-token';
// import { Button } from '@/components/atom/button';

interface FirebaseContextType {
  isOnline: (id: number) => boolean;
  loading: boolean;
}

const FirebaseContext = React.createContext<FirebaseContextType | undefined>(undefined);

export const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // const { token, notificationPermissionStatus } = useFcmToken();
  const [data, setData] = React.useState(null); // Store database data
  const [user, setUser] = React.useState<User | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(true);

  // console.log({ token, notificationPermissionStatus });

  // const handleTestNotification = async () => {
  //   const response = await fetch('/api/send-notification', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       token: token,
  //       title: 'Test Notification',
  //       message: 'This is a test notification',
  //       link: '/contact',
  //     }),
  //   });

  //   const data = await response.json();
  //   console.log(data, 'tega');
  // };

  const contextValue = React.useMemo(() => {
    const isOnline = (id: number) => Boolean(data?.[id]);
    return { isOnline, loading };
  }, [data, loading]);

  // Handle Firebase authentication
  React.useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(authFirebase, (user) => {
      if (user) {
        setLoading(true);
      } else {
        signInAnonymously(authFirebase).catch((error) => {
          console.error('Authentication error:', error);
        });
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  // Listen to Firebase database changes
  React.useEffect(() => {
    if (!loading) return;

    const dbRef = ref(database, '/online');
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val();
        setData(value); // Update data only if it changes
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [loading]);

  return (
    <FirebaseContext.Provider value={contextValue}>
      {children}
      {/* <Button className="fixed top-0 left-4 z-[9999]" onClick={() => handleTestNotification()}>
        Send Notification
      </Button> */}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const context = React.useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};
