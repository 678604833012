import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { SHOP_ENDPOINTS } from '@/constants/apiPath';
import {
  CreateEventPayloadType,
  PublicEventResponse,
  UserCommunityParamsType,
  AuthenticatedUserRegisterForEventType,
  UnAuthenticatedUserRegistrationType,
  GroupedEventsType,
  UpdateEventTitleFormType,
  UpdateEventPayloadType,
  CancelEventPayload,
  OpenCloseTicketSalesPayload,
  SendEventRemainderPayload,
  AttendeeParamsType,
  EventAttendeeListResponse,
  UpdateEventDetailFormType,
  EventDownloadParamsType,
  EventsParamsType,
} from '@/types';
import { ChannelEnum } from '@/utils/enums';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { AxiosError } from 'axios';
import { cache } from 'react';

export async function createEvent(body: CreateEventPayloadType) {
  const res = await axiosClientInstance.post(
    SHOP_ENDPOINTS.createEvent,
    {
      ...body,
      physicalLocation: {
        address: body.physicalLocation,
        state: '',
        country: 'Nigeria',
      },
    },
    {
      baseURL: FUSION_API.communitiesApi,
    }
  );
  return res.data?.data;
}
export async function updateEventTitle(payload: UpdateEventTitleFormType) {
  const values: Partial<UpdateEventPayloadType> = {
    title: payload.eventName,
    description: payload.description,
    cost: payload.price,
    eventId: payload.eventId,
    numberAvailable: payload.numbersAvailable,
  };

  const body = payload.base64
    ? {
        ...values,
        images: [
          {
            base64: payload.base64 ?? '',
            contentType: payload.imageFile?.type,
            name: payload.imageFile?.name,
          },
        ],
      }
    : { ...values };

  try {
    const res = await axiosClientInstance.put(SHOP_ENDPOINTS.updateEvent, body, {
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function updateEventDetail(payload: UpdateEventDetailFormType) {
  try {
    const res = await axiosClientInstance.put(
      SHOP_ENDPOINTS.updateEvent,
      { ...payload, channelId: ChannelEnum.Web },
      {
        baseURL: FUSION_API.communitiesApi,
      }
    );
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

export async function getExploreShop(params: UserCommunityParamsType) {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getGrouped, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? 'Something went wrong');
  }
}

export async function getGroupedEvents(
  params: UserCommunityParamsType
): Promise<GroupedEventsType> {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getGrouped, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? 'Something went wrong');
  }
}

export async function getAllShopItems(params: EventsParamsType): Promise<PublicEventResponse> {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getExplore, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? 'Something went wrong');
  }
}

export async function getProductCategories(params: { PageNumber: number; PageSize: number }) {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getProductCategories, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? 'Something went wrong');
  }
}

export async function getShopItemBySlug(params: { slug?: string }) {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getBySlug, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? 'Something went wrong');
  }
}

export async function getEventItemById(params: { Id?: string }) {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getEventById, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? 'Something went wrong');
  }
}

export const getEventItemBySlug = cache(async function (params: { slug?: string }) {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getEventBySlug, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
});

export async function getEventAttendees(
  params: AttendeeParamsType
): Promise<EventAttendeeListResponse> {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getAttendeeList, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? 'Something went wrong');
  }
}

export async function downloadEventAttendeeList(params: EventDownloadParamsType) {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.downloadAttendeeList, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? 'Something went wrong');
  }
}

export async function authenticatedUserRegisterForEvent(
  body: AuthenticatedUserRegisterForEventType
) {
  const res = await axiosClientInstance.post(SHOP_ENDPOINTS.registerForFreeEvent, body, {
    baseURL: FUSION_API.communitiesApi,
  });
  return res.data?.data;
}

export async function unloggedInRegisterForEvent(body: UnAuthenticatedUserRegistrationType) {
  const res = await axiosClientInstance.post(SHOP_ENDPOINTS.registerForFreeEvent, body, {
    baseURL: FUSION_API.communitiesApi,
  });
  return res.data?.data;
}

export async function getUpcomingEventsByCommunityId(params: { CommunityId?: string | number }) {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.upcomingEvents, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

export async function cancelEvent(body: CancelEventPayload) {
  const res = await axiosClientInstance.post(SHOP_ENDPOINTS.cancelEvent, body, {
    baseURL: FUSION_API.communitiesApi,
  });
  return res.data?.data;
}

export async function openCloseTicketSales(body: OpenCloseTicketSalesPayload) {
  const res = await axiosClientInstance.post(SHOP_ENDPOINTS.openCloseTicketSales, body, {
    baseURL: FUSION_API.communitiesApi,
  });
  return res.data?.data;
}

export async function sendEventRemainder(body: SendEventRemainderPayload) {
  const res = await axiosClientInstance.post(SHOP_ENDPOINTS.sendEventRemainder, body, {
    baseURL: FUSION_API.communitiesApi,
  });
  return res.data?.data;
}
